<template>
  <div>
    <div class="search">
      <div class="title">核销人员列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="姓名:">
          <el-input v-model="params.nickName"></el-input>
        </el-form-item>
        <el-form-item label="手机号码:">
          <el-input v-model="params.phone"></el-input>
        </el-form-item>
        <el-form-item label="状态:">
          <el-select v-model="params.status" placeholder="请选择">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="正常" :value="1"></el-option>
            <el-option label="禁用" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="getList">查询</el-button>
          <el-button type="primary" @click="reset">重置</el-button>
          <el-button type="primary" @click="add">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%"
      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->
      <el-table-column type="index" width="50" align="center"> </el-table-column>
      <el-table-column prop="userName" label="姓名" width="120" align="center"> </el-table-column>
      <el-table-column label="头像 " width="120" align="center">
        <template slot-scope="scope">
          <el-image
            style="width: 70px; height: 70px"
            :src="scope.row.headImg"
            :preview-src-list="[scope.row.headImg]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="手机号" width="160" align="center">
      </el-table-column>
      <el-table-column prop="userWork" label="所在景区"  align="center">
      </el-table-column>
      <el-table-column prop="userJob" label="职位" width="110" align="center">
      </el-table-column>
      <el-table-column prop="strStatus" label="状态" width="110" align="center">
      </el-table-column>
      <el-table-column prop="addTime" label="添加时间" width="200" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center" width="300">
        <template slot-scope="scope">
          <el-button
            type="success"
            size="mini"
            @click="upData(scope.row, 1)"
            v-if="scope.row.status == 2"
            >启用</el-button
          >
          <el-button
            type="warning"
            size="mini"
            @click="upData(scope.row, 2)"
            v-if="scope.row.status == 1"
            >禁用</el-button
          >
          <el-button type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>
          <el-button type="primary" size="mini" @click="upData(scope.row, 0)"
            >删除</el-button
          >
          <el-button type="primary" size="mini" @click="$router.push('writeoffForUser?userId='+scope.row.userId)"
            >查询核销点</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form :model="addList" :rules="addRules" ref="addRuleFormRef">
        <el-form-item label="姓名:" prop="userName">
          <el-input v-model="addList.userName"></el-input>
        </el-form-item>
        <el-form-item label="手机号:" prop="phone">
          <el-input v-model="addList.phone"></el-input>
        </el-form-item>
        <el-form-item label="所在景区:" prop="userWork">
          <el-input v-model="addList.userWork" :maxlength="50" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="职位:" prop="userJob">
          <el-input v-model="addList.userJob"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  writeOffPeopleList,
  writeOffPeopleListAdd,
  writeOffPeopleListUpdata,
} from "../../../api/administrator.js";
export default {
  data() {
    return {
      dialogVisible: false,
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        userName: "",
        phone: "",
        status: "",
      },
      addList: {
        userId: 0,
        userName: "",
        phone: "",
        userWork: "",
        userJob: "",
      },
      addRules: {
        userName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { min: 2, max: 10, message: "长度在 2 到 10 个字符", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        userWork: [
          { required: true, message: "请输入所在景区", trigger: "blur" },
          { min: 1, max: 50, message: "长度在 1 到 50 个字符", trigger: "blur" },
        ],
        userJob: [{ required: true, message: "请输入职位", trigger: "blur" }],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    rowKey(row) {
      return row.userId;
    },
    async getList() {
      //   this.params.beginTime = this.date ? this.date[0] : "";
      //   this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await writeOffPeopleList(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.userName = "";
      this.params.status = 0;
      this.date = null;
      this.getList();
    },
    add() {
      this.dialogVisible = true;
    },
    submit() {
      this.$refs.addRuleFormRef.validate(async (valid) => {
        if (valid) {
          if (this.load) return;
          this.load = true;
          const { data } = await writeOffPeopleListAdd(this.addList);
          this.load = false;
          if (data.code == 0) {
            this.$message.success("添加成功");
            this.dialogVisible = false;
            this.getList();
          } else {
            this.$message.error(data.msg);
          }
        } else {
          return false;
        }
      });
    },
    edit(row) {
      Object.keys(this.addList).forEach((key) => {
        this.addList[key] = row[key];
      });
      this.dialogVisible = true;
    },

    upData(row, status) {
      let str = "";
      switch (status) {
        case 0:
          str = "删除";
          break;
        case 1:
          str = "禁用";
          break;
        case 2:
          str = "启用";
          break;
      }
      this.$confirm("是否确认" + str + "?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await writeOffPeopleListUpdata({
            userId: row.userId,
            status,
          });
          if (data.code == 0) {
            this.$message.success(data.msg);
            this.getList();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
